<template>
  <div class="about" :class="{ isMobile }">
    <div class="top">
      <h2>关于我们</h2>
      <div class="info">
        <p>
          聚客量化是四川赤壁量化科技有限公司旗下致力于金融行业金融软件和量化工具开发服务和销售为一体的高新型企业
        </p>
      </div>
    </div>
    <div class="hint">
      <h2>公司简介</h2>
      <div class="content">
        <p>
          聚客量化是四川赤壁量化科技有限公司旗下致力于金融行业金融软件和量化工具开发服务和销售为一体的高新型企业。
        </p>
        <p>
          我们拥有专业的IT工程师团队，并且对股票、期货、金融衍生品领域的交易规则较为熟悉，从2018年至今，已为居间团队、私募团队或个人交易爱好者提供技术发开服务累计超过5000次，经验丰富。
        </p>
        <p>公司在多年经营中，口碑良好，并获得国家认可AAA级别诚信企业</p>
      </div>
      <div class="certificate"></div>
      <p>
        公司提供专业的科技金融技术服务，实现一对一专属定制，解决您在交易中的大部分问题
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style lang="scss" scoped>
.about {
  .top {
    width: 100%;
    height: 480px;
    margin-top: -40px;
    background: url('/static/img/about.png') no-repeat;
    background-size: 100% 100%;

    h2 {
      padding-top: 150px;
      text-align: center;
      margin-bottom: 15px;
      height: 59px;
      line-height: 59px;
      color: rgba(255, 255, 255, 1);
      font-size: 42px;
    }

    .info {
      text-align: center;
      margin-bottom: 54px;
      height: 30px;
      line-height: 30px;
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
    }
  }

  .hint {
    padding: 40px;
    text-align: center;

    h2 {
      text-align: center;
      height: 42px;
      line-height: 42px;
      color: rgba(51, 51, 51, 1);
      font-size: 30px;
    }

    .certificate {
      width: 603px;
      height: 722px;
      margin: 0 auto;
      background: url('/static/img/certificate.png') no-repeat;
      background-size: 100% 100%;
    }
  }
}

.about.isMobile {
  h2 {
    text-align: center;
    height: 42px;
    line-height: 42px;
    color: rgba(51, 51, 51, 1);
    font-size: 24px;
  }

  p {
    font-size: 14px;
  }
  .certificate {
    width: 100%;
    height: 400px;
    margin: 0 auto;
    background: url('/static/img/certificate.png') no-repeat;
    background-size: 100% 100%;
  }

  .top {
    height: 200px;
    padding: 0 20px;
    box-sizing: border-box;
    h2 {
      color: #fff;
      padding-top: 20px;
    }
  }
}
</style>
